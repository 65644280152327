import React, { Component } from "react";
import _size from "lodash/size";
import PropTypes from "prop-types";
import * as styled from "./style";

const TABLET_MIN_WIDTH = 760;
const DESKTOP_MIN_WIDTH = 1299;

class ContactList extends Component {
  state = {
    contactsPerRow: 4
  }

  componentDidMount() {
    this.getNumberOfRows();
    window.addEventListener("resize", this.getNumberOfRows);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.getNumberOfRows);
  }

  getDisplayLink = (linkType, linkDisplayName, linkValue) => {
    // TODO : This need re-structuring
    const href = (linkType === "text" || linkType === "other") ?
      <styled.DisplayText dangerouslySetInnerHTML={{ __html: linkDisplayName }} /> :
      (linkType === "email") ?
        <a href={"mailto:" + linkValue}><styled.DisplayLink dangerouslySetInnerHTML={{ __html: linkDisplayName }} /></a> :
        (linkType === "phone") ?
          <a href={"tel://" + linkValue}><styled.DisplayLink dangerouslySetInnerHTML={{ __html: linkDisplayName }} /> </a> :
          <a href={`/redirect?url=${linkValue}`} rel="noopener noreferrer"
            onClick={(e) => { this.manageHrefClick(e) }}><styled.DisplayLink dangerouslySetInnerHTML={{ __html: linkDisplayName }} /></a>;
    return href;
  }

  getNumberOfRows = () => {
    const contactsPerRow = window.innerWidth <= TABLET_MIN_WIDTH ? 1 : window.innerWidth <= DESKTOP_MIN_WIDTH ? 2 : 4;
    if (this.state !== contactsPerRow) {
      this.setState({ contactsPerRow })
    }
  }

  manageHrefClick = (e) => {
    e.preventDefault();
    if (e.currentTarget && e.currentTarget.href)
      window.open(e.currentTarget.href)
  }

  render() {
    const { contactInfo, companyName, label, displaylogo } = this.props;
    const { contactsPerRow } = this.state;
    if (_size(contactInfo) > 0) {
      const data = contactInfo.sort((o1, o2) => o1.order - o2.order);
      return (
        <styled.ContactDetails displaylogo={displaylogo}>
          <styled.ContactHeading>{label}</styled.ContactHeading>
          <styled.InfoContainer>
            {data.map((datum, index) => (
              <styled.Contact key={index}>
                <styled.ContactInner>
                  {datum.linkLabelValue ?
                    <styled.PhoneLink dangerouslySetInnerHTML={{__html: datum.linkLabel}}></styled.PhoneLink>
                    : <styled.Label dangerouslySetInnerHTML={{__html: datum.linkLabel}}>
                    </styled.Label>
                  }
                  {this.getDisplayLink(datum.linkType, datum.linkDisplayName, datum.linkValue)}
                </styled.ContactInner>
              </styled.Contact>
            ))}
          </styled.InfoContainer>
        </styled.ContactDetails>
      );
    } else {
      return null;
    }
  }
}

ContactList.propTypes = {
  resources: PropTypes.shape({
    contactInfo: PropTypes.array,
    companyName: PropTypes.string,
    label: PropTypes.string
  })
};
export default React.memo(ContactList);
